import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  enable(event) {
    theme_colors_embed_dark("themePreviewEvent", "enableCustomTheme", {checked: event.target.checked});
    theme_colors_embed_light("themePreviewEvent", "enableCustomTheme", {checked: event.target.checked});
  }

  update(event) {
    let value = event.target.value
    let type = event.target.dataset.type

    if (value.length === 7 && value[0] === "#") {
      theme_colors_embed_dark("themePreviewEvent", "changeColor", {type: type, value: value});
      theme_colors_embed_light("themePreviewEvent", "changeColor", {type: type, value: value});
    }
  }
}
